
import React, { useRef } from "react";
import emailjs from "emailjs-com";

import "./contact.css";

const SERVICE_ID = "service_6ypvc68";
const TEMPLATE_ID = "template_foka92t";
const YOUR_PUBLIC_KEY = "IQ984vm3ivLv6caMX";

const ContactForm = () => {
  const form = useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, YOUR_PUBLIC_KEY)
      .then((result) => {
          setIsLoading(false);
          setSuccess(true);
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div>
      {success && <p>Thank for your email, we'will get back to you soon.</p>} 
      {isLoading && <p>Sending</p>} 
      {!isLoading && !success &&
      <form ref={form} onSubmit={sendEmail}>
      <div><label>Name</label></div>
      <input type="text" name="user_name" />
      <div><label>Email</label></div>
      <input type="email" name="user_email" />
      <div><label>Message</label></div>
      <textarea name="message" />
      <div><input type="submit" value="Send" /></div>
      </form>
      }
    </div>    
  );
};
  
export default ContactForm;
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
    <header class="header-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-2 col-sm-3">
            <div class="logo">
                <Link to="/">N<span>Photos</span></Link>
            </div>
            <div><p><b>Under construction</b></p></div>
          </div>
          <div class="col-md-8 col-sm-9">
            <div class="navbar-area">
              <nav class="site-navbar">
                <ul>
                  <li><Link class="active" to="/">Home</Link></li>
                  <li><Link to="/galleries">Galleries</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
                <button class="nav-toggler">
                  <span></span>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
    <Outlet />
    </>
  )
};

export default Layout;


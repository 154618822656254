import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCJTbhmjjbgmUZiV0R-JdyjvC3vdPvN4XY",
    authDomain: "n-photos.firebaseapp.com",
    projectId: "n-photos",
    storageBucket: "n-photos.appspot.com",
    messagingSenderId: "825904266584",
    appId: "1:825904266584:web:352e5a78f54299d8c0792d",
    measurementId: "G-1QBJRSJ4NP"
  };
  
  // Initialize Firebase
const firebase = initializeApp(firebaseConfig);
  
export default firebase;
import './home.css';

function Galleries() {
  return (
    <div className="home">
        <h5 class="card-title">Under construction</h5>        
    </div>
  );
}

export default Galleries;

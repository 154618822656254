import { useParams } from "react-router-dom";
import  firebase  from './Service/firebase';
import { ref, getDownloadURL, listAll } from "firebase/storage"
import { getStorage } from 'firebase/storage';
import React, { useState, useEffect } from 'react';

export default function Details() {
  let { id } = useParams();

  const [imageURLs, setImageURLs] = useState([]);

  const getImages = () => {
        const storage = getStorage(firebase)
        const listRef = ref(storage, id+'/')
        setImageURLs([]);
        listAll(listRef).then((res) => {
            res.items.forEach((itemRef) => {           
                const path = itemRef._location.path_;
                const urlRef = ref(storage, path);
                getDownloadURL(urlRef).then((url) => {
                    setImageURLs(oldArray => [...oldArray, url].sort()); //When we do sort outside, it givs an error
                });
            });
        }).catch((error) => {
            console.log(error);
        });
  }

  useEffect(() => {
    getImages();
  }, []);
  
  return(
    <div>
      <h2>{id}</h2>
      <div id="gallery">
        {imageURLs.map((image) => (
          <img src={image} alt=''/>
        ))}      
      </div>
    </div>
  )
}